import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'TopBar',
  props: {
    title: {
      type: String,
      default: () => {
        return "Home";
      }
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        title: __props.title,
        "left-text": "返回",
        "left-arrow": "",
        onClickRight: _cache[0] || (_cache[0] = $event => _ctx.$router.push({
          name: 'home'
        })),
        onClickLeft: _cache[1] || (_cache[1] = $event => _ctx.$router.go(-1))
      }, {
        right: _withCtx(() => [_createVNode(_component_van_icon, {
          name: "wap-home-o"
        })]),
        _: 1
      }, 8, ["title"]);
    };
  }
};