import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import './assets/css/global.scss';
import R from '@/utils/http';
import 'vant/es/dialog/style';
import './assets/js/rem.js';
// Prism
import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';
import 'vant/es/notify/style';
import 'vant/es/toast/style';
import './assets/font/iconfont.css';
VMdEditor.use(vuepressTheme, {
    Prism,
});

import VueMarkdownEditor from '@kangc/v-md-editor';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

VueMarkdownEditor.use(createMermaidPlugin());



import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
VueMarkdownEditor.use(createEmojiPlugin());

import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
VueMarkdownEditor.use(createTodoListPlugin());



import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';
VueMarkdownEditor.use(createAlignPlugin());


import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
VueMarkdownEditor.use(createLineNumbertPlugin());


import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';
import store from './utils/store';

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});

router.afterEach((e) => {
   if(e.name !=='showWord' )
   {
      document.title = '融创词条';
   }
});

  
import TopBar from "@/components/TopBar";

const app = createApp(App)
app.config.globalProperties.R = R
app.config.globalProperties.baseUrl = process.env.VUE_APP_API_BASEURL ?? ''
console.log('baseURL', process.env.VUE_APP_API_BASEURL)
app.component('TopBar', TopBar);
app.use(router).use(VMdEditor).use(store).use(VMdPreview).mount('#app')
