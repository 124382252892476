import {
    createStore
} from 'vuex'
const store = createStore({
    namespaced: true,
    state() {
        return {
            tabIndex: 0,
        }
    },
    mutations: {
        setTabIndex(state, index) {
            state.tabIndex = index;
        },

    },
    actions: {
        setTabIndex(store, index) {
            store.commit("setTabIndex", index)
        },
    }
})
export default store
