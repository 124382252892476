import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
const routes = [
    {
        path: '/',
        name: 'root',
        redirect: 'home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: HomeView
            },

        ]
    },
    {
        path: '/create/word/:catalogId',
        name: 'createWordByCatalog',
        component: () => import('../views/Word/CreateWord.vue')
    },

    {
        path: '/create/word',
        name: 'createWord',
        component: () => import('../views/Word/CreateWord.vue')
    },
    {
        path: '/show/word/:id',
        name: 'showWord',
        component: () => import('../views/Word/ShowWord.vue')
    },
    {
        path: '/word/:url',
        name: 'showWordByUrl',
        component: () => import('../views/Word/ShowWord.vue')
    },
    {
        path: '/edit/word/:id',
        name: 'editWord',
        component: () => import('../views/Word/EditWord.vue')
    },
    {
        path: '/list/word',
        name: 'listWord',
        component: () => import('../views/Word/ListWord.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '../views/LoginView.vue')
    },


   
    // {
    //     path: '/catalog/:url',
    //     name: 'catalogShow',
    //     component: () => import( '../views/Catalog/ShowView.vue')
    // },
    {
        path: '/edit/catalog/:id',
        name: 'editCatalog',
        component: () => import( '../views/Catalog/EditCatalog.vue')
    },
    {
        path: '/show/catalog/:id',
        name: 'ListCatalogWord',
        component: () => import( '../views/Catalog/ListCatalogWord.vue')
    },
    {
        path: '/catalog/:url',
        name: 'ListCatalogWordByUrl',
        component: () => import( '../views/Catalog/ListCatalogWordByUrl.vue')
    },
    {
        path: '/list/catalog',
        name: 'catalogList',
        component: () => import( '../views/Catalog/ListView.vue')
    },
    {
        path: '/create/catalog',
        name: 'createCatalog',
        component: () => import('../views/Catalog/CreateCatalog.vue')
    },

    {
        path: '/manage/catalog',
        name: 'catalogManage',
        component: () => import( '../views/Catalog/ManageView.vue')
    },
    {
        path: '/searchPlace',
        name: 'searchPlaceholder',
        component: () => import('../views/Search/SearchPlace.vue')
    },
    {
        path: '/searchresult',
        name: 'searchResult',
        component: () => import('../views/Search/SearchRes.vue')
    },
    {
        path: '/user',
        name: "user",
        children: [
            {
                path: '/user/center',
                name: 'userCenter',
                component: () => import('../views/User/CreateCenter.vue')
            },
            {
                path: '/user/register',
                name: "register",
                component: () => import('../views/User/RegisterView.vue')

            },
            {
                path: '/user/baseInfo',
                name: 'baseInfo',
                component: () => import( '../views/User/BaseInfo.vue')
            },
            {
                path: '/user/modifyPassword',
                name: "modifyPassword",
                component: () => import('../views/User/Account/ModifyPassword.vue')

            }, 
            {
                path: '/auth/forgetPwd',
                name: "forgetPwd",
                component: () => import('../views/User/Account/ForetPassword.vue')

            }, 
             {
                path: '/user/modifyNickname',
                name: "modifyNickname",
                component: () => import('../views/User/Account/ModifyNickname.vue')

            },
            {
                path: '/user/modifyName',
                name: "modifyName",
                component: () => import('../views/User/Account/ModifyName.vue')

            },
        ]

    },
    {
        path: '/admin',
        name: "admin",
        children: [
            {
                path: '/admin/users',
                name: 'adminManagerUsers',
                component: () => import('../views/Admin/UsersView.vue')
            },
        ]

    },
    {
        path: '/help/center',
        name: "HelpCenter",
        component: () => import('../views/Help/HelpCenter.vue')

    },

    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
